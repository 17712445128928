.article:nth-of-type(2n) {
	@include mq($from: md) {
		flex-direction: row-reverse;
	}
	background: #fff;
	.article__image {
		animation-name: fadeInRight!important;
	}
}
.article {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: $padding-xl * 2;
	background: rgb(240, 241, 248);
	background: linear-gradient(
		180deg,
		rgba(240, 241, 248, 1) 0%,
		rgba(240, 241, 248, 1) 45%,
		rgba(255, 255, 255, 1) 100%
	);

	&__text {
		width: 40%;
	}
	&__image {
		width: 60%;
	}

	&__image {
		img {
			// height: 720px;
			object-fit: cover;
			object-fit: contain;
			width: 100%;
		}
	}

	&__text {
		// padding: 0 5%;
		max-width: calc(535px + 10%);
	}

	.button {
		margin-left: auto;
		margin-top: 20px;
		min-width: 160px;
		display: block;
		width: fit-content;
	}

	@include mq($until: md) {
		flex-direction: column;
		margin-bottom: $padding-lg;

		&__text,
		&__image {
			width: 100%;
			padding: 0;
			max-width: 550px;
		}

		img {
			height: auto;
		}
	}
}
