// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}
@mixin make-container($padding-x: $container-padding-x, $padding-y: $container-padding-y) {
    width: 100%;
    padding-right: $padding-x;
    padding-left: $padding-x;
    padding-top: $padding-y;
    padding-bottom: $padding-y;
    margin-right: auto;
    margin-left: auto;
}

@mixin make-padding-top($padding-t: $padding-top) {
    padding-top: $padding-t;
}
@mixin make-padding-bot($padding-b: $padding-bottom) {
    padding-bottom: $padding-b;
}
@mixin make-padding-left($padding-l: $padding-left) {
    padding-left: $padding-l;
}
@mixin make-padding-right($padding-r: $padding-right) {
    padding-right: $padding-r;
}
.p-vt-4 {
    @include make-padding-top(4vw!important);
}
.p-vb-4 {
    @include make-padding-bot(4vw!important);
}
.p-vl-8 {
    @include make-padding-left(8vw!important);
}
.p-vr-8 {
    @include make-padding-right(8vw!important);
}

.custom-container { 
    @include make-container(8vw,8vw)
} 