.house {
    &__slider {
        * {
            height: 80vh;
        }

        img {
            width: 100%;
        }


    }

    &__main, &__content {
        display: flex;
    }

    &__left, &__right {
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    &__left {
        padding-right: 100px;
    }
    
    &__symbol {
        background-color: $color-main;
        display: flex;
        flex-direction: column;
        height: fit-content;
        padding: 20px;
        align-items: center;

        * {
            color: white;
        }

        h1 {
            font-size: rem(100px);
            font-weight: 400;
            margin: 0;
            line-height: 1.1;
        }

        h2 {
            font-size: rem(25px);
            font-weight: 300;
            margin: 0;
        }
    }

    ol {
        margin-left: 0;
        li {
            border-top: 1px solid $color-main;
            margin-top: 5px;
            padding: 20px 0 10px 20px;
            position: relative;
            font-size: rem(20px);
            span, div {
                font-size: inherit;
            }
            div {
                display: flex;
                justify-content: space-between;
            }
            counter-increment: li;
            &::before { 
                content: counter(li) ".";
                position: absolute;
                left: 0;
            }
        }
    }

    &__buttons {
        margin-top: 50px;
        margin-left: auto;
        display: flex;

        &> div {
            display: flex;
            margin-bottom: 15px;
        }
    }

    &__svg {
        width: 100%;
        position: relative;

        svg {
            width: 100%;
            height: auto;
        }

        &::before {
            content: '';
            position: absolute;
            border-top: 3px solid $color-main;
            width: calc(100% + 8vw);
            left: -$padding-xxl;
        }
    }

    &__areas {
        margin: 50px 0 50px 50px;

        h3 {
            margin-bottom: 0;
        }

        * {
            color: $color-main;
        }

        br {
            display: none;
        }

    }

    &__model {
        display: flex;
        margin-top: 50px;
        position: relative;

        img {
            display: block;
            width: 90%;
        }
    }

    &__direction {
        height: 100px;
        width: 100px !important;
        margin-top: 50px;
        position: absolute;
        right: 0;
    }

    @include mq($until: xxl) {
        &__left {
            padding-right: 0;
        }

        &__buttons {
            flex-direction: column;
        }

        &__right {
            padding-left: 100px;
        }
    
        &__symbol {
            padding: 15px;

            h1 {
                font-size: rem(80px);
            }

            h2 {
                font-size: rem(20px);
            }
        }

    }

    @include mq($until: xl) {
        &__areas {
            margin: 40px 0 40px 40px;

            h3 {
                font-size: rem(22px);
            }
        }

        &__symbol {
            padding: 15px;

            h1 {
                font-size: rem(70px);
                line-height: 1;
            }

            h2 {
                font-size: rem(17px);
            }
        }

        &__right {
            padding-left: 80px;
        }

        &__direction {
            margin-top: 10px;
        }

    }

    @include mq($until: lg) {
        &__main {
            position: relative;
        }

        &__areas {
            margin: 40px 0;
        }

        &__symbol {
            position: absolute;
            top: -122px;
        }

        &__direction {
            height: 70px;
            width: 70px !important;
            margin-top: 0;
        }

        &__model {
    
            img {
                width: 100%;
            }
        }

        &__right {
            padding-left: 50px;
        }
    }

    @include mq($until: md) {

        &__buttons {
            margin-left: -15px;
        }
        
        &__right {
            padding: 0;
        }

        &__content {
            flex-direction: column;
        }

        &__symbol {
            top: -110px;
        }

        &__slider {
            * {
                height: 90vh;
                min-height: 500px;
            }
        }

        &__left, &__right {
            width: 100%;
        }

        &__areas {
            width: 100%;
        }
    }

    @include mq($until: sm) {
        &__symbol {
            top: -105px;
        }
    }

    @include mq($until: xs) {
        &__symbol {
            top: -100px;
        }
    }

}

.w-spacer {
    margin-top: 120px;
    h3 {
        color: $color-main;
        text-align: center;
    }

    &__box {
        padding: 150px 250px;
        height: 55vw;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    iframe {
        border: 10px solid white;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    }

    @include mq($until: xl) {
        &__box {
            padding: 150px 100px;
            height: 65vw;
        }
    }

    @include mq($until: lg) {
        margin-top: 100px;

        &__box {
            padding: 120px 70px;
            height: 75vw;
        }
    }

    @include mq($until: md) {
        margin-top: 80px;

        &__box {
            background-size: cover;
            padding: 70px 0px;
            height: 100vh;
            min-height: 650px;
        }
    }
}

.slick-slide {
    max-height: 90vh;
}