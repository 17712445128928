.footer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;

  &__realization {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      transition: color .3s ease-in-out;
    }

    &:hover span {
      color: $color-main;
    }

    img {
      margin-left: 15px;
    }
  }
}

.pic-box {
  padding-left: rem(30px);
}

.footer-contact {
  background-color: #162087;

  h6,
  p {
    color: white;
  }

  @include mq($from: xl) {
    margin-top:rem(-80px);

    .pic-box {
    }
  }
}

.footer-section {
  a {
    color: white;
  }
  .card-text {
    a {
      color: black;
    }
  }
}
.pic-column {
  @include mq($from:xl) {
    justify-content: space-around!important;
  }
}