.widget {
	width: 100%;
	margin-top: 100px;
	position: relative;
    height: 45vw;


	svg {
		width: 100%;
		height: 100%;
	}

	#front-bg {
		pointer-events: none;
	}

	path { transition: fill .4s ease; }

	.apt {
		cursor: pointer;
		&:hover {
			transition: all .4s;
			opacity: .5;
		}
	}

	&__tip {
		position: absolute;
		display: flex;
		flex-direction: column;
		padding: 30px;
		border-radius: 5px;
		background-color: aliceblue;
		width: 300px;
		z-index: 5;
		box-shadow: 0 0 3px rgba(139, 139, 139, 0.342);
		opacity: 0;
		transition: opacity .5s;
		z-index: -500;
	}

	&__tip-info {
		display: flex;
		justify-content: space-between;
	}

	&_back {
		display: none;
	}

	&_front, &_back {
	}

	.video {
		height: 45vw;
	}
	video {
		width: 100%;
		height: auto;
		display: none;
		position: absolute;
		top: 0;
		left: 0;
	}

	.rotate {
		position: absolute;
		left: calc(85% - 380px);
		z-index: 10;
		top: -10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 380px;

		&__title {
			font-size: rem(18px);
			font-weight: 600;
			color: #171F8A;
		}

		div {
			display: flex;
			margin-right: 20px;
		}

		span {
			width: 54px;
			margin: 0 5px;
			
			svg {
				cursor: pointer;

				&:hover {
					.arrow-bg {
						fill: #171F8A;
					}

					.arrow-arrow {
						fill: white;
					}
				}
			}
		}

		@include mq($until: lg) {
			left: calc(95% - 380px);
		}

		@include mq($until: md) {
			width: 300px;
			left: calc(50% - 150px);

			span {
				width: 44px;
			}
			
			&__title {
				font-size: rem(16px);
			}
		}
	}

	.next, .prev {
		cursor: pointer;
	}
}

.arrow-bg {
	fill: white;
	transition: all .4s;
}

.arrow-stroke {
	stroke: #171F8A;
}

.arrow-arrow {
	fill: #171F8A;
}

@include mq($until: md) {
	.apt {
		display: none;
	}
}