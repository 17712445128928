.top-background-img {
    height: 100vh;
    @include mq($until: lg) {
        background-repeat: no-repeat;
        background-size: cover;
    }
    & .text-container {
        position: relative;
        top: 40%;
        left: 10%;
        width: 50%;
        & h1,
        h2,
        h3 {
            color: $color-font-light;
            margin: 0px;
        }
        & h1 {
            font-size: rem(80px);
            font-weight: 900;
        }
        & h2 {
            font-size: rem(50px);
            font-weight: 600;
        }
        & h3 {
            font-size: rem(30px);
            font-weight: 100;
        }
        @include mq($until: lg) {
            left: 0;
            top: 50%;
            text-align: center;
            width: 100%;
        }
    }
}
