.container-vw {
    @include make-container(8vw, 4vw);
}
.architecture {
    border-bottom: $border-thin;
    h2,h3 {
        color:$color-main;
    }
    &__left,
    &__right {
        border-top: $border-thin;
        // border-bottom: $border-thin;
    }
    &__left {
        border-right: $border-thin;
        min-height: 110vh;
    }

    .border-img {
        &__left {
            border-bottom: $border-thin;
        }
        &__right {
            border-top: $border-thin;
            border-bottom: $border-thin;
            min-width:100%;
        }
    }
    &__button {
        height:100%;
        width:100%;
        border-right: $border-thin;
        min-height: 150px;
    }

    &--mobile {
        &__right {
            svg {
                min-width: 100%;
                max-width: 100%;
                height: unset!important;;
            }
            img {
                min-width: 100%;
            }
        }
        &__button {
            min-height:100px;
            background-color: $color-main;
            h3 {
                color:$color-font-light;
            }
        }
    }
}
.border-box-1 {
    // border-bottom: $border-thin;
    border-right: $border-thin;
}
