.icons-set {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  flex-direction: row;

  &__item {
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: center;
    min-width: 20vw;
    min-height: 150px;
    justify-self: center;
    background: #F4F4FA 0% 0% no-repeat padding-box;
    margin: 10px;
    flex: 1 1 160px;
    margin: 10px;

    img {
      max-width: 150px;
      padding: 10px;
      filter: opacity(0.8) drop-shadow(0 0 0 $color-main) contrast(250%) contrast(190%) brightness(110%);
    }

    span {
      padding: 10px;
    }

    &::before {
      content: none;
    }
  }

  &__item-text {
    font-weight: 600;
    font-size: rem(16px);
  }

  @include mq($until: md) {
    flex-direction: column;

    &__item {
      width: 80%;

      img {
        // width: rem(60px);
      }
    }

    &__item-text {
      font-size: rem(15px);
    }
  }

  @include mq($until: sm) {
    &__item-text {
      font-size: rem(13px);
    }
  }
}

.icons-grid {
  &--8 {
    grid-template-columns: repeat(8, 1fr);
  }

  &--7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &--6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &--5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &--4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--2 {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq($until: md) {
    &--8 {
      grid-template-columns: repeat(4, 1fr);
    }

    &--7 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--6 {
      grid-template-columns: repeat(3, 1fr);
    }

    &--5 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--4 {
      grid-template-columns: repeat(2, 1fr);
    }

    &--3 {
      grid-template-columns: repeat(1, 1fr);
    }

    &--2 {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}