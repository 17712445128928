.map {
    overflow: scroll;
    svg {
        width: unset;
        height: unset;
        max-width: 100%;
        @include mq($until: lg) {
            max-width:300%;
            min-height:80vh;
        }
    }
    @include mq($until: lg) {
        min-height: 80vh;
    }

}
