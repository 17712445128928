.level {
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(240, 241, 248, 1) 100%);
  padding-bottom: 100px;
  border-bottom:$border-thin;
  &__item {
    display: flex !important;
    justify-content: space-between;
  }

  &__text {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  &__image {
    width: 55%;
  }

  @include mq($until: lg) {
    &__text {
      width: 40%;
    }
  }

  @include mq($until: sm) {
    &__item {
      flex-direction: column;
    }

    &__slider {
      padding: 0;
    }

    &__text,
    &__image {
      width: 100%;
    }

    &__image {
      img {
        max-width: 290px;
        margin: 0 auto;
      }
    }
  }
}
