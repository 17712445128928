.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 100px;
  border-bottom: solid 1px $color-white;
  @include mq($until: lg) {
    border-bottom: 1px solid $color-main;
  }
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
    z-index: 1000;
    @include mq($until: md) {
      background-color: $color-white;
      padding: 0 2vw !important;
    }
    &.sticky {
      background-color: $color-main;
      z-index: 1000;
      @include mq($until: md) {
        background-color: $color-white;
      }
    }
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    min-height: 100px;
    border: solid 1px $color-white;
    &--logo {
      position: relative;
      z-index: 2;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__menuList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: rem(40px);
    padding: 0;
    font-size: rem(20px);
    &--fullscreen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-light;
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active,
    &--active,
    &--open,
    &:hover {
      color: $color-main;
    }
  }
  &__right {
    border-left: solid 1px $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-main;
    min-height: 100px;
    & a {
      color: $color-font-light;
      font-size: rem(20px);
      margin-top: 10px;
    }
  }
}

.subsite {

  .header {

    border: none;

    &--fixed.sticky {
      background-color: transparent;
    }

    &__right {
      border-left: none;
    }

    &--fixed {
      background-color: transparent;
      padding: 0!important;
    }

    &__right {
      width: 200px;
      max-width: 200px!important;
      flex: 0 0 200px;
    }

    @include mq($until: md) {
      &__right {
        display: flex!important;
      }
    }

    @include mq($until: sm) {
      &__right {
        width: 150px;
        max-width: 150px!important;
        flex: 0 0 150px;
      }

      top: -10px;
      &__right, &__left {
        min-height: 80px;
      }
    }
  }

}
