.button {
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  border-radius: 5px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: solid 1px $color-main;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;
  }

  &--secondary{
    background-color: transparent;
    color: $color-main;
    border-color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-main;
      border-color: $color-main;
    }
  }

  &__house {
    border-radius: 0;
    color: $color-main;
    background-color: #e8e8f4;
    border: none;
    padding: 15px 25px;
    margin-left: 15px;
    
    &-sm {
      padding: 15px 15px;
    }

    &-lg {
      width: 310px;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      background-color: $color-main;
      color: white;

      path {
        fill: white;
      }
      circle {
        stroke: white;
      }
    }

    svg {
      height: 30px;
      width: 30px;

      path {
        fill: $color-main;
      }
      
      circle {
        stroke: $color-main;
      }
    }

    @include mq($until: lg) {
      &__house {
        &-lg {
          width: 280px;
        }
      }
    }
  }
}